@media print {
    table {
        table-layout: fixed;
        width: 100%;
    }
    
    td{
        word-wrap:break-word;
        width: 20%;
        vertical-align: top;
      }
  }